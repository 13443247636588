<template>
  <div class="container">
    <div v-if="client">
      <div class="termsTab" v-if="capable">
        <div class="row mb-0">
          <div class="col my-auto">
            <h5 class="mb-0 client-tab-heading">Terms & Agreements</h5>
          </div>
        </div>

        <!--  -->
        <hr class="my-3" />

        <div class="card border-0 bg-light shadow-sm mb-4 noprint">
          <div class="card-header bg-light pb-0">
            <h5>Terms shared with {{ client.name }}</h5>
          </div>
          <div class="card-body">
            <div class="row" v-if="agreements.length > 0">
              <div class="col">
                <div class="card">
                  <table class="table rounded table-hover bg-white mb-0">
                    <thead>
                      <tr>
                        <th scope="col" style="border-top: 0px">Document</th>
                        <th scope="col" style="border-top: 0px">Version</th>
                        <th scope="col" style="border-top: 0px">Issued</th>
                        <th scope="col" style="border-top: 0px">Status</th>
                        <th scope="col" style="border-top: 0px"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="a in agreements" class="cursor-pointer">
                        <td @click="viewResponse(a)">
                          <i class="fas fa-handshake me-2 text-primary"></i>
                          {{ a.term.title }}
                        </td>
                        <td @click="viewResponse(a)">{{ a.term.version }}</td>
                        <td @click="viewResponse(a)">
                          {{ a.created_at | formatDateShort }}
                        </td>
                        <td @click="viewResponse(a)" class="text-capitalize">
                          <span v-if="a.client_responded_date" class="small">
                            Client Responded:
                            {{ a.client_responded_date | formatDateShort }}
                          </span>
                          <i class="small" v-else> Awaiting Response </i>
                        </td>
                        <td class="text-end">
                          <div class="dropdown" v-if="!a.client_responded_date">
                            <button
                              class="btn btn-sm btn-light dropdown-toggle"
                              type="button"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            ></button>
                            <div
                              class="dropdown-menu"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <a
                                class="dropdown-item"
                                @click.stop="reSendAgreement(a)"
                              >
                                <i class="fa-fw far fa-sync me-2"></i>
                                Send Reminder
                              </a>
                              <a
                                class="dropdown-item"
                                @click.stop="cancelRequest(a)"
                              >
                                <i
                                  class="fa-fw far fa-times me-2 text-danger"
                                ></i>
                                Cancel Request
                              </a>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="row mb-2" v-if="agreements.length === 0 && !loading">
              <div class="col text-center">
                <i class="fas fa-info-circle text-primary fa-4x"></i>
                <p class="mb-0">
                  No terms have been shared with {{ client.name }} yet.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="card border-0 bg-light shadow-sm noprint">
          <div class="card-header bg-light pb-0">
            <h5>Share Terms with {{ client.name }}</h5>
          </div>
          <div class="card-body">
            <div class="row mb-4" v-if="terms.length === 0">
              <div class="col">
                <p class="alert alert-primary">
                  <i class="fa fa-info-circle me-2"></i>
                  No clinic T&C documents have been created. You can create
                  custom T&Cs for your clinic in the Settings area, or use one
                  of our pre-loaded T&C templates to get started.
                </p>
              </div>
            </div>
            <div class="row" v-if="terms.length > 0">
              <div class="col">
                <div class="card">
                  <table class="table rounded table-hover bg-white mb-0">
                    <thead>
                      <tr>
                        <th scope="col" style="border-top: 0px">Document</th>
                        <th scope="col" style="border-top: 0px">Version</th>
                        <th scope="col" style="border-top: 0px"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="term in terms">
                        <td>
                          <i class="fas fa-handshake me-2 text-primary"></i>
                          {{ term.title }}
                        </td>
                        <td>{{ term.version }}</td>
                        <td class="text-end">
                          <button
                            class="btn btn-light btn-sm ms-1"
                            @click="viewTerm(term)"
                            v-tooltip:bottom="'Preview'"
                          >
                            <i class="fa fa-eye"></i>
                          </button>
                          <button
                            class="btn btn-light btn-sm ms-1"
                            @click="shareTerm(term)"
                            v-tooltip:bottom="'Share with Client'"
                          >
                            <i class="fa fa-share"></i>
                            Share with Client
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <response-modal
          :client="client"
          :user="user"
          :agreement="expandedAgreement"
        >
        </response-modal>

        <preview-modal
          name="previewTermModal"
          :preview-term="selectedTerm"
        ></preview-modal>
        <!--  -->
      </div>
    </div>
    <loading v-else> </loading>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import PreviewModal from "../../settings/areas/terms-partials/PreviewModal";
import ResponseModal from "./terms-partials/ResponseModal";
export default {
  data() {
    return {
      client: null,
      agreements: [],
      terms: [],
      newTerm: {
        title: null,
        body: null,
      },
      selectedTerm: null,
      loading: true,
      capable: false,
      expandedAgreement: null,
    };
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  methods: {
    fetchClientAgreements() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.client.id +
            "/agreements"
        )
        .then(({ data }) => {
          this.agreements = data;
          this.loading = false;
        });
    },
    fetchTerms() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL + "/settings/terms/fetch-terms-published"
        )
        .then(({ data }) => {
          this.terms = data;
        });
    },
    viewTerm(term) {
      this.selectedTerm = term;
      $("#previewTermModal").modal("show");
    },
    shareTerm(term) {
      var result = confirm(
        "Please confirm that you want to share '" +
          term.title +
          "' with " +
          this.client.name +
          "."
      );
      if (result) {
        this.$axios
          .post(
            process.env.VUE_APP_API_URL +
              "/clients/api/" +
              this.client.id +
              "/share-terms",
            {
              clinic_term_id: term.id,
            }
          )
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            this.fetchClientAgreements();
          });
      }
    },
    viewResponse(agreement) {
      this.expandedAgreement = agreement;
      $("#termsResponseModal").modal("show");
    },
    reSendAgreement(r) {
      var confirmed = confirm(
        "Are you sure you wish to re-send this agreement?"
      );
      if (confirmed) {
        this.$axios
          .post(
            process.env.VUE_APP_API_URL +
              "/clients/api/" +
              r.client.id +
              "/reshare-terms",
            {
              id: r.id,
            }
          )
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            this.loadAgreementData();
          });
      }
    },
    cancelRequest(r) {
      var confirmed = confirm(
        "Are you sure you wish to cancel this form request? Your client will no longer be able to complete this form unless it is re-issued."
      );
      if (confirmed) {
        this.$axios
          .post(
            process.env.VUE_APP_API_URL +
              "/clients/api/" +
              this.client.id +
              "/cancel-agreement",
            {
              id: r.id,
            }
          )
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            this.fetchClientAgreements();
          });
      }
    },
    async fetchClientData() {
      const {
        data: { client },
      } = await this.$axios.get(
        process.env.VUE_APP_API_URL + "/clients/api/" + this.$route.params.id
      );

      this.client = client;
    },
  },
  filters: {
    formatDate(date) {
      if (date) {
        return moment(date).format("LL");
      }
      return "-";
    },
    formatDateShort(date) {
      return moment(date).format("LL");
    },
  },
  async mounted() {
    this.$stash.clientInView = this.$route.params.id;
    await this.fetchClientData();

    if (this.user.capabilities.includes("legal_docs")) {
      this.capable = true;
    } else {
      this.$router.push("/upgrade");
    }

    this.fetchTerms();
    this.fetchClientAgreements();
  },
  components: {
    ResponseModal,
    PreviewModal,
    VueEditor,
  },
};
</script>

<style>
</style>
