<template>
  <div>
    <div
      class="modal noprint"
      tabindex="-1"
      role="dialog"
      id="termsResponseModal"
    >
      <div
        class="modal-dialog modal-lg modal-dialog-centered noprint"
        role="document"
      >
        <div class="modal-content bg-white noprint">
          <div class="modal-header">
            <h5 class="modal-title client-tab-heading">
              Client: {{ client.name }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal" data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="card bg-light" v-if="agreement">
              <div class="card-body">
                <h5>Terms</h5>
                <hr />

                <div class="card mb-4">
                  <div
                    class="card-body"
                    style="overflow-y: scroll; max-height: 450px"
                  >
                    <div v-html="agreement.term.body"></div>
                  </div>
                </div>

                <h5>Client Response</h5>
                <hr />

                <div class="row mb-3" v-for="consent in agreement.consents">
                  <div class="col">
                    <p
                      class="alert"
                      :class="consent.accepted ? 'alert-success' : 'alert-dark'"
                    >
                      <i
                        v-if="!consent.accepted"
                        class="far fa-times-circle text-danger me-1"
                      ></i>
                      <i v-else class="far fa-check-circle me-1"></i>
                      {{ consent.consent.title }}
                      <span
                        v-if="consent.date_accepted"
                        class="text-primary small"
                      >
                        (Accepted {{ consent.date_accepted | formatDate }})
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-light btn-sm me-2"
              data-bs-dismiss="modal" data-dismiss="modal"
              aria-label="Close"
            >
              Close
            </button>
            <button
              class="btn btn-outline-primary btn-sm"
              @click="downloadPDF()"
            >
              <i class="far fa-file-pdf me-1"></i>
              Download PDF
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="printit show-when-printing" v-if="agreement">
      <h5>Terms</h5>
      <hr />

      <div class="mb-4">
        <div v-html="agreement.term.body"></div>
      </div>

      <h5>Client Response</h5>
      <hr />

      <div class="row mb-3" v-for="consent in agreement.consents">
        <div class="col">
          <p>
            <i
              v-if="!consent.accepted"
              class="far fa-times-circle text-danger me-1"
            ></i>
            <i v-else class="far fa-check-circle text-success me-1"></i>
            {{ consent.consent.title }}
            <span v-if="consent.date_accepted" class="text-primary small">
              (Accepted {{ consent.date_accepted | formatDate }})
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["user", "client", "agreement"],
  data() {
    return {
      busy: false,
    };
  },
  methods: {
    downloadPDF() {
      $("#termsResponseModal").modal("hide");
      window.print();
    },
  },
  mounted() {
    $("#termsResponseModal").on("hide.bs.modal", (e) => {
      this.$emit("closed");
    });
  },
  computed: {},
  filters: {
    formatDate(date) {
      return moment(date).format("LLL");
    },
  },
  watch: {},
  components: {},
};
</script>
